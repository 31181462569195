var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
var SvgColor = forwardRef(function (_a, ref) {
    var src = _a.src, sx = _a.sx, other = __rest(_a, ["src", "sx"]);
    return (_jsx(Box, __assign({ component: "span", className: "svg-color", ref: ref, sx: __assign({ width: 24, height: 24, display: 'inline-block', bgcolor: 'currentColor', mask: "url(".concat(src, ") no-repeat center / contain"), WebkitMask: "url(".concat(src, ") no-repeat center / contain") }, sx) }, other)));
});
export default SvgColor;
